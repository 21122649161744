export type Theme = typeof theme;

const theme = {
  color: { red: "red", green: "green", gray10: "#DFE0E6", gray20: "#C2C3CC" },
  fontSize: {
    small: "9px",
    medium: "17px",
    big: "40px",
  },
  sizes: {
    maxWidth: "55rem",
    sliderWidth: "35rem",
  },
};

export default theme;
