import chpLogo from "./assets/logo/logo.svg";
import styled from "styled-components";

function App() {
  return (
    <Container>
      <MainWrapper>
        <Logo src={chpLogo} />
        <StyledText>Site em construção</StyledText>
      </MainWrapper>
      <Footer>
        <p>Av. Eng. Duarte Pacheco, Amoreiras, Torre 2, Piso 8, Sala 5</p>
        <p>+351 21 397 3386</p>
        <p>geral@chparquitectos.com</p>
        <CopyText>
          © {new Date().getFullYear()} CHP arquitectos, all rights reserved
        </CopyText>
      </Footer>
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
`;

const MainWrapper = styled.div`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  gap: 1rem;
`;

const Logo = styled.img`
  width: 25rem;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;

  & > p {
    font-size: 0.8rem;
    margin: 0;
    margin-bottom: 0.3rem;
  }
`;

const StyledText = styled.p`
  font-size: 0.9rem;
  color: ${({ theme }) => theme.color.gray20};
`;

const CopyText = styled.span`
  margin-top: 1.8rem;
  font-size: 0.8rem;
  color: ${({ theme }) => theme.color.gray20};
`;
